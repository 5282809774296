import React from "react";
import styles from "./Testimonials.module.css";
import { FormattedHTMLMessage, FormattedMessage } from "react-intl";
import TradedeoublerLogo from "../../assets/tradedoubler-logo.png";
import WebgainsLogo from "../../assets/webgains-logo.png";
import PartnerizeLogo from "../../assets/partnerize-logo.png";
import ForthgladeLogo from "../../assets/forthglade-logo.png";
import AwinLogo from "../../assets/awin-gray.png"
import SimplyCook from "../../assets/SimplyCook.svg"
import CJLogo from "../../assets/cj-icon128x128.png"

const Testimonials = ({title, partners, showPersonName = false}) => {
    const logos = {
        Tradedoubler: TradedeoublerLogo,
        Webgains: WebgainsLogo,
        Partnerize: PartnerizeLogo,
        Forthglade: ForthgladeLogo,
        Awin: AwinLogo,
        SimplyCook,
        CJ: CJLogo,
    };

    return (
        <div className={styles.Testimonials}>
            <h2><FormattedMessage id={title} /></h2>
            <div className={styles.TestimonialsPlaceholder}>
                {
                    partners.map((partner, i) => (
                        <div className={styles.TestimonialItem} key={i}>
                            <p><FormattedHTMLMessage id={`testimonial${partner}`} /></p>
                            {showPersonName && <span className={styles.TestimonialItemName}><FormattedHTMLMessage id={`testimonial${partner}Name`} /></span>}
                            <img src={logos[partner]} alt={partner} />
                        </div>
                    ))
                }
            </div>
        </div>
    );
};

export default Testimonials;